
import { Pagation } from "@/composables/usePagation";
import useRenderTable from "@/composables/useRenderTable";
import useRequest from "@/composables/useRequest";
import { RequestParams } from "@/types/request";
import useFetchSearch from "./composables/useFetchSearch";
import { defineComponent, reactive, toRefs, ref, onActivated } from "vue";
import { useRouter } from "vue-router";
import useCreateAndUpdate from "./composables/useCreateOrUpdate";
import useMessageTip from "@/composables/useMessageTip";
import copyField from "@/utils/copyField";
import { setSession } from "@/utils/sesssion";
import { useStore } from "@/store/index";

type EnvList = {
  id: number;
  code: string;
  name: string;
};

export default defineComponent({
  name: "globalVariable",
  setup() {
    const router = useRouter();
    const { tipMessage } = useMessageTip();
    const searchParams = reactive({
      keyword: "",
      env_id: []
    });
    const tableData = reactive({
      list: [],
      total: 0
    });
    const currentPage = ref(1);

    const store = useStore();
    const envList = store.state.envList;
    const pageTableRef = ref<any>();
    const stopAutoGetData = ref<boolean>(false);

    // 分页以及获取数据
    const getData = async (pagationParams?: Pagation, flag?: boolean, params?: any) => {
      stopAutoGetData.value = flag == undefined ? false : true;
      currentPage.value = (pagationParams && pagationParams.pageIndex) || 1;

      const { response } = useRequest(useFetchSearch, params || searchParams);
      const { pagation } = useRenderTable(response);
      let {
        data: { list, count }
      } = await pagation(pagationParams);
      tableData.total = count;
      tableData.list = list;
    };
    getData();
    const handleConditionSearch = async () => {
      await getData(pageTableRef.value.getCurrentPage(), true, searchParams);
      stopAutoGetData.value = false;
    };
    const handleName = (val: string) => {
      if (!val) handleConditionSearch();
    };
    const handleEnv = (val: string) => {
      if (!val) handleConditionSearch();
    };
    onActivated(() => {
      getData();
    });
    /**
     * 创建和更新
     */
    const dialogParams = reactive<RequestParams.CreateAndUpdateGlobalList>({
      id: 0,
      remark: "",
      key: ""
    });
    const { rules, handleCreateAppShowDialog, dialogVisible, handleCreateApp, formRef, handleUpdateApp, handleCloseDialog, handleDelete } = useCreateAndUpdate(
      getData,
      pageTableRef,
      dialogParams
    );
    const handleDetail = (row: any) => {
      setSession("appDetail", row);
      if (row.env_list) {
        setSession("currentEnvironment", row.env_list[0]);
      }
      router.push({
        name: "globalVariableDetail",
        query: Object.assign({ ...router.currentRoute.value.query })
      });
    };

    const environment = (val: any) => {
      let text = "";
      if (val && val.length) {
        val.forEach((element: any) => {
          text += element.name.replace("环境", "") + "/";
        });
      }
      if (text) text = text.substr(0, text.length - 1);
      return text;
    };

    // 查看值
    const lookVal = ref<EnvList[]>([]);
    const dialogLookVisible = ref(false);
    const handleLookVal = (val: EnvList[]) => {
      if (!val.length) return tipMessage(400, "暂无值,请先配置环境");
      dialogLookVisible.value = true;
      lookVal.value = val;
    };

    const handleCloseLookDialog = () => {
      dialogLookVisible.value = false;
    };

    // 复制源码地址
    const handleCopyUrl = (val: string) => {
      copyField(val);
    };

    return {
      handleCloseDialog,
      tipMessage,
      rules,
      handleCreateAppShowDialog,
      handleConditionSearch,
      stopAutoGetData,
      getData,
      ...toRefs(tableData),
      pageTableRef,
      searchParams,
      dialogVisible,
      dialogParams,
      handleCreateApp,
      envList,
      formRef,
      environment,
      handleUpdateApp,

      handleLookVal,
      lookVal,
      dialogLookVisible,
      handleCloseLookDialog,
      handleCopyUrl,

      handleDelete,
      handleDetail,
      handleName,
      handleEnv,
      currentPage
    };
  }
});
